import React from "react"
import { graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { shuffleArray, stripHtml } from "../utils/utils"

import { Section, Columns } from "../components/Containers"
import { Text, Image } from "../components/Core"
import { SocialIconLink } from "../components/SocialReviews"
import { Button } from "../components/Button"

const BasicPage = ({ pageContext, location, data }) => {
  const { language } = pageContext
  const post = data.allBlogsJson.nodes[0]
  const queryPage = `/${data.queryPage.nodes[0].title}`

  const allBlogs = shuffleArray(data.relatedBlogs.nodes)

  const cta = data.cta.nodes[0].cta

  const findRelatedBlog = (topics, blogs) => {
    let result = blogs[0]
    if (!topics || topics.length === 0) {
      result = blogs[0]
      return result
    }

    for (let i = 0; i < blogs.length; i++) {
      if (topics.some(t => blogs[i].topics.includes(t))) {
        result = blogs[i]
        break
      }
    }
    return result
  }

  const recommendedBlog = findRelatedBlog(post.topics, allBlogs)

  return (
    <Layout navSpacer language={language} className="single-blog">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Section zeroTop zeroBottom>
        <Columns sideColumnsSize={1}>
          <Button
            className="px-40--mobile"
            buttonText="Back to Blogs"
            secondary
            href="/blog/"
            goBack
          />
        </Columns>
      </Section>

      <Section zeroTopDesktop zeroBottom>
        <Columns sideColumnsSize={1}>
          <div className="column">
            <div className="single-blog__hero">
              <div className="single-blog__photo px-40--mobile">
                <Image publicId={post.hero} />
              </div>
              <div className="single-blog__heading">
                <Text className="px-40--mobile" as="h1" text={post.heading} />
                <span className="single-blog__date block px-40--mobile">
                  Updated{" "}
                  <time>
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit"
                    }).format(new Date(post.publishedDate))}
                  </time>
                </span>
                <div className="single-blog__author px-40--mobile">
                  <div className="single-blog__author-image">
                    <Image publicId="77P/DEV/doctor-fan-bio" />
                  </div>
                  <p className="px-0">By Dr. Fan</p>
                </div>
              </div>
            </div>
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={1}>
          <div className="column">
            <div className="single-blog__content">
              <Text useStringReplace text={post.body} />
              <div className="single-blog-sidebar">
                <h5 className="bold--600 mb-2 px-40--mobile">
                  You can also read:
                </h5>

                <Link
                  title="Read the full article"
                  to={`/${recommendedBlog.title}`}
                  className="single-blog__recommended clear-decoration">
                  <div className="px-40--mobile">
                    <Image publicId={recommendedBlog.hero} />
                  </div>
                  <Text
                    className="bold--600 px-40--mobile"
                    as="h5"
                    text={recommendedBlog.heading}
                  />
                  <Text
                    className="single-blog__recommended-body"
                    text={stripHtml(
                      recommendedBlog.body.substring(0, 400).replace("@", "")
                    )}
                  />

                  <div className="single-blog__recommended-link bold--600 px-40--mobile">
                    <div>
                      <FontAwesomeIcon icon={faArrowRight} />{" "}
                    </div>
                    <span>READ MORE</span>
                  </div>
                </Link>

                <div className="px-40--mobile">
                  <div className="gold-gradient line mt-3 mb-3"></div>
                </div>

                <div className="single-blog__share px-40--mobile">
                  <h5 className="bold--600">Share this article!</h5>
                  <SocialIconLink
                    bgColor="#000"
                    className="mt-1"
                    platform="Facebook"
                    link={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                      location.href
                    )}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </Columns>
      </Section>

      <Section zeroBottom>
        <Columns sideColumnsSize={1}>
          <div className="column">
            <div className="blog-cta">
              <Image publicId={cta.image} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}>
                <Text className="px-0" as="h3" text={cta.blurb} />

                <div className="mt-3">
                  <Button
                    className="mt-0"
                    buttonText="Contact Us"
                    href="/contact-us/"
                    destination="internal"
                    appearance="contained"
                  />
                </div>
              </div>
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const basicPageQuery = graphql`
  query singleBlogQuery($title: String!) {
    allBlogsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        hero
        heading
        body
        publishedDate
        topics
      }
    }
    relatedBlogs: allBlogsJson(filter: { title: { ne: $title } }) {
      nodes {
        title
        hero
        heading
        body
        topics
      }
    }
    cta: allTemplateSettingsJson(
      filter: { templateType: { eq: "single-blog" } }
    ) {
      nodes {
        cta {
          blurb
          heading
          image
          socialLinks {
            link
            platform
          }
        }
      }
    }
    queryPage: allUniquePagesJson(filter: { template: { eq: "blogs-query" } }) {
      nodes {
        title
      }
    }
  }
`

export default BasicPage
